.selectize-control {
  margin-right: 0;
  position: relative;
  width: 150px;
}

.selectize-input {
  border: 0;
  box-shadow: none;
  height: 100%;
  padding-right: 1rem;
  vertical-align: bottom;

  &.focus {
    box-shadow: none;
  }

  &.full {
    background-color: transparent;
  }
}

.selectize-dropdown {
  border: 0;
  box-shadow: none;
}


.selectize-dropdown,
.selectize-input,
.selectize-input input {
  @include font-meta-nativation;
  line-height: normal;
}


.lang-select {
  margin-right: 1rem;
  width: 4rem;
}


.selectize-dropdown-content {
  box-shadow: 1px -4px rgba(0, 0, 0, .1);
}


.option {
  &.selected {
    transition: color, background .16s ease-in-out;
  }

  &.active {
    background-color: theme-color('primary');
    color: $white;
  }
}
