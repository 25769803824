.support {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__heading {
    margin: 0;
    padding-bottom: $grid-gutter-width;
    text-align: center;
  }



  &__item {
    color: inherit;
    opacity: 0;
    padding-right: $grid-gutter-width;
    text-align: center;
    text-decoration: none;
    transform: translateY(15px);

    &:hover {
      color: inherit;
      text-decoration: none;
      .support__icon {
        background-color: $color-hempel-blau-80;
      }

      #Combined-Shape,
      #Shape {
        fill: $white;
      }
    }
  }

  &.active {
    .support__item {
      @include trans;
    }
  }

  &__title {
    text-align: center;
  }

  &__icon {
    align-items: center;
    background: $white;
    border-radius: 100%;
    display: flex;
    height: 7rem;
    justify-content: center;
    margin-bottom: calc(#{$grid-gutter-width} / 2);
    transition: background .2s ease-in-out;
    width: 7rem;
  }
}
