.form-control {
  background-color: $color-grau-2;
  border-color: transparent;
  border-radius: 3px;
  color: $color-schwarz;

  &::placeholder {
    color: $color-schwarz;
  }
}

.main-form {
  &__title {
    margin: 2rem 0 1rem;
  }

  .input-group {
    margin-bottom: 1rem;

    &.input-group--button {
      justify-content: flex-end;
    }
  }
}
