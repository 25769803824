.services-teaserbox {
  $block: &;

  &__item {
    background-color: theme-color('primary');
    margin-bottom: $grid-gutter-width;

    &.light-gray {
      background-color: $color-grau-3;
    }

    &.very-light-gray {
      background-color: $color-grau-1;

      #{$block}__title {
        &,
        &:hover {
          color: $gray-900;
        }
      }
    }

    &.white {
      background-color: $white;

      #{$block}__title {
        &,
        &:hover {
          color: $gray-900;
        }
      }
    }

    &.dark-gray {
      background-color: $color-schwarz;
    }

    &.blue {
      background-color: $color-hempel-blau-60;
    }

    &.hempel-blue {
      background-color: $color-hempel-blau;
    }
  }

  &__video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__title {
    color: $white;
    display: block;
    padding: 6px;

    &:hover {
      color: $white
    }
  }

  &__media {
    height: 0;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative;

    img {
      @extend .img-fluid;
      width: 100%;
    }

    .fa-youtube,
    .fa-youtube::after {
      color: red;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .fa-youtube {
      cursor: pointer;
      font-size: 3rem;
      opacity: .8;
      transition: opacity .3s linear;
      z-index: 3;

      &::after {
        background-color: $white;
        content: '';
        display: block;
        height: 50%;
        width: 30%;
        z-index: -1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
