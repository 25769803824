// Keyvisual Start
.keyvisual {
  .focuspoint {
    height: 50vh;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-image: none;
    height: 0;
    width: 0;
  }

  .swiper-button-next {
    border-bottom: 1.5rem solid transparent;
    border-left: 1.5rem solid $white;
    border-top: 1.5rem solid transparent;
    right: $grid-gutter-width;
  }

  .swiper-button-prev {
    border-bottom: 1.5rem solid transparent;
    border-right: 1.5rem solid $white;
    border-top: 1.5rem solid transparent;
    left: $grid-gutter-width;
  }
}

// Heading Start
.heading {
  background: $color-hempel-blau;

  h1, .h1 {
    color: $white;
    font-weight: 700;
    margin: 0;
    padding: 1rem 0;
    text-align: center;
  }
}
