$table-bg: #ebeef0;

.table {

  thead {
    th {
      background-color: $table-bg;
      border-bottom: 0;
      border-left: 0;
    }
  }

  tbody {
    td {
      border-bottom: 0;
      border-left: 0;
    }
  }

  &.table-bordered {
    border: 0;

    td,
    th {
      &:last-child {
        border-right: 0;
      }
    }
  }
}
