.weight-calc {
  color: $white;

  h2 {
    color: $white;
  }

  .col-form-label {
    align-items: center;
    display: flex;
  }

  .form-group {
    position: relative;
  }

  .box {
    background: $color-grau-2;
    border-radius: 0 3px 3px 0;
    color: $color-schwarz;
    font-size: 1rem;
    line-height: 1.5;
    padding: .375rem .75rem;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 1px;
    width: 4rem;
  }

  .form-control {
    background-color: $white;
  }

  .btn-primary {
    background-color: $color-hempel-blau-80;
    border-color: $color-hempel-blau-80;
  }

  &__data {
    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
    }


    background-color: $color-hempel-blau-80;
    padding: 1rem;
    width: 50%;

    .inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__img {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }

    @include media-breakpoint-down(lg) {
      justify-content: flex-end;
    }
    display: flex;
  }


  .opacity {
    opacity: .8;
  }
}
