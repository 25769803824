body {
  // background-color: $body-bg;
  font-weight: 200;
}

.heading-margin {
  margin-bottom: 12px;
  margin-top: $grid-gutter-width;
}

.page-overlay {
  &.fade {
    transition: visibility $animation-base, opacity $animation-base;
    visibility: hidden;

    &.in {
      visibility: visible;
    }
  }
}


strong {
  font-weight: 700;
}

section,
footer {
  position: relative;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// disable outline on links and buttons
a,
button {
  &:focus {
    outline: none !important;
   }
}

button,
input,
select,
textarea {
  font-family: $font-family-base;
}

.btn-primary {
  border-radius: .1875rem;
  min-width: 10.3125rem;

  &:hover {
    background-color: $color-hempel-blau-80;
    border-color: $color-hempel-blau-80;
  }

}

.btn-gray {
  background-color: $color-schwarz;
  border-color: $color-schwarz;
  color: $white;

  &:hover {
    background-color: darken($color-schwarz, 10%);
    border-color: darken($color-schwarz, 10%);
    color: $white;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: $color-schwarz;
  font-family: 'Noto Sans Cond';
  font-weight: 700;
}

h3 {
  margin-bottom: $grid-gutter-width;
}


.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
// @include media-breakpoint-up(xl) {
// }

.padding-top-bottom {
  padding: $multiple-gutter-width 0;
}

.no-padding {
  padding: 0;
}



// background colors
.light-gray {
  background-color: $color-grau-2;
}

.dark-gray {
  background-color: $color-schwarz;
}

.very-light-gray {
  background-color: $color-grau-1;
}

.hempel-blue {
  background-color: $color-hempel-blau;
}

.white {
  background-color: $color-weiss;
}

.fg-grey-2 {
  color: $color-grau-2;
}

.fg-white{
  color: $color-weiss;
}

.fg-black{
  color: $color-schwarz;
}

section {
  opacity: 0;
  transition: opacity 1.2s ease-out;

  &.visible {
    opacity: 1;
  }
}
