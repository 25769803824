@mixin trans {
  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      opacity: 1;
      transform: translateY(0);
      transition: transform .35s ease-in-out #{$i * .06s},
      opacity .35s ease-in-out #{$i * .06s};
    }
  }
}


// transition delay
@mixin delaytranstion {
  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      transition-delay: #{$i * 90ms};
    }
  }
}


// $transs: width .4s ease-in-out, width .4s cubic-bezier(.645, .045, .0355, 1);
//
// .image-overlay {
//   background-color: $color-hempel-blau;
//   height: 100%;
//   position: absolute;
//   right: 0;
//   top: 0;
//   transition: $transs;
//   z-index: 1000;
//
//   &--show-image {
//     width: 0;
//   }
//
//   &--hide-image {
//     left: 0;
//     right: auto;
//     width: 100%;
//   }
// }
