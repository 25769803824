//  Colors:

// Hempel Farben
$color-econoxx-grun: #87ab16;
$color-grau-1: #ebeef0;
$color-grau-2: #d9dcde;
$color-grau-3: #969899;
$color-hempel-blau: #003b90;
$color-hempel-blau-60: #006aff;
$color-hempel-blau-80: #005bdb;
$color-schwarz: #4a4a4a;
$color-weiss: #fff;


//  Font Styles:

// Main Navigation font style
$font-main-navigation-font-size: 1.375rem;
$font-main-navigation-line-height: 2rem;
$font-main-navigation-text-align: left;
$font-main-navigation-color: $color-schwarz;
$font-main-navigation-font-weight: 400; // original
$font-main-navigation-hover-color: $color-hempel-blau;
$font-meta-nativation-font-size: 1.125rem;
$font-meta-nativation-color: $color-grau-3;
$font-meta-navigation-important-color: $color-hempel-blau;

@mixin font-main-navigation  {
  color: $font-main-navigation-color;
  font-size: $font-main-navigation-font-size;
  font-weight: $font-main-navigation-font-weight;
  line-height: $font-main-navigation-line-height;
  text-align: $font-main-navigation-text-align;
}

// Main Navigation Hover font style
@mixin font-main-navigation-hover {
  color: $font-main-navigation-hover-color;
}

@mixin font-meta-nativation  {
  color: $font-meta-nativation-color;
  font-size: $font-meta-nativation-font-size;
  font-weight: $font-main-navigation-font-weight;
  line-height: $font-main-navigation-line-height;
  text-align: $font-main-navigation-text-align;
}

// Meta Navigation important font style
@mixin font-meta-navigation-important {
  color: $font-meta-navigation-important-color;
}
