.certificate-teaser {
  @include media-breakpoint-down(md) {
    margin-bottom: $grid-gutter-width;
  }
  perspective: 526px;
  position: relative;
  transform-style: preserve-3d;

  .face,
  .back {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: all .5s ease-out;
    width: 100%;
  }

  .face {
    position: relative;
    transform: rotateY(0deg);
    z-index: 1;
  }

  .back {
    background-color: theme-color('primary');
    color: $white;
    height: 100%;
    position: absolute;
    top: 0;
    transform: rotateY(-180deg);
    z-index: 0;
  }

  &.active {
    .face {
      transform: rotateY(180deg);
      z-index: 0;
    }

    .back {
      transform: rotateY(0);
      z-index: 1;
    }
  }




  &__img {
    img {
      @extend .img-fluid;
      width: 100%;
    }
  }

  &__title {
    background-color: $color-hempel-blau-80;
    color: $white;
    margin: 0;
    padding: .5rem 1rem;

    p {
      margin: 0;
    }
  }

  &__text {
    background-color: theme-color('primary');
    color: $white;
    display: flex;
    justify-content: space-between;
    padding: .2rem 1rem;

    p {
      margin: 0;
    }
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    p {
      color: $white;
      // height: 100%;
      margin: 0;
      overflow-y: auto;
      padding: 1rem;
      width: 100%;
    }

    .flip-close {
      bottom: .5rem;
      position: absolute;
      right: 1rem;

      .far {
        font-size: 1.25rem;
      }
    }

  }

  .flip-open {
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
