.media--person {
  border: 0;

  > img {
    margin-right: $grid-gutter-width;
  }

  .media-body {
    height: auto;
    padding: 0;
    width: auto;

    ul li a .fas {
      width: 25px;
    }

    .media-title {
      font-size: 17px;
    }

    .media-subtitle {
      position: relative;
      top: -5px;
    }
  }
}
