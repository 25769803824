.news {
  border-bottom: 1px solid $color-grau-2;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  &__item {
    padding-left: 1rem;
  }

  &__dates {
    color: $color-grau-3;
    margin: 0;
  }

  &__heading {
    border-bottom: 1px solid $color-grau-2;
    padding-bottom: 1rem;
  }

  &__morelink {
    color: $color-hempel-blau;
    transition: $transition-color;

    &:hover {
      color: $color-hempel-blau-80;
    }
  }

  &--slider {
    // border-bottom: 0;
    border: 2px solid $color-grau-2;
    margin: 0;
    padding: 0;

    .news__heading--slider {
      border-bottom: 0;
      padding-bottom: 0;
    }

    .focuspoint {
      height: 16rem;
      // padding: 2rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-color: theme-color('primary');
      background-size: 12px 30px;
      bottom: 5px;
      height: 30px;
      top: auto;
    }

    .swiper-button-prev {
      left: auto;
      right: 40px;
    }

    &__link {
      display: block;
    }
  }
}
