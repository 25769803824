.tech {
  @include media-breakpoint-down(md) {
    margin-bottom: $grid-gutter-width;
  }
  display: block;
  padding: #{$grid-gutter-width / 2};

  &__wrapper {
    position: relative;

    img {
      width: 100%;
    }

    .fas {
      color: #cc4b4c;
      font-size: 3rem;
      left: 17px;
      position: absolute;
      top: 15px;
      transition: color .2s linear;
    }
  }

  &__text {
    background-color: $white;
    font-size: 12px;
    font-weight: 900;
    margin: 0;
    padding: .3rem;
    text-align: right;
  }

  &:hover {
    .fas {
      color: theme-color('primary');
    }
  }
}
