.breadcrumb {
  &-background {
    background-color: theme-color('primary');

    .breadcrumb {
      background-color: transparent;
      margin-bottom: 0;
      padding-left: 0;
    }


  }
}


.breadcrumb-item {
  a {
    color: $white;
  }

  + .breadcrumb-item::before {
    color: $white;
    content: "\f0da";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }

  &.active {
    color: $white;
  }

}
