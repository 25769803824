// transparent blue color
$transparent-blue: rgba(0, 59, 144, .56);

.image-teaser {
  // background-color: $color-schwarz;
  padding: $multiple-gutter-width 0 $grid-gutter-width;

  h2 {
    color: $white;
    font-weight: 700;
    line-height: 2rem;
    margin-bottom: 0;
    padding-bottom: $multiple-gutter-width;
    text-align: center;
  }

  // img {
  //   margin-bottom: $grid-gutter-width;
  //   max-width: 100%;
  //   width: 100%;
  // }

  &__link {
    color: $white;
    display: block;
    position: relative;

    &:hover {
      color: $white;

      .image-teaser__overlay {
        opacity: 1;
      }
    }
  }

  &__title {
    background: $color-schwarz;
    color: $white;
    display: inline-block;
    font-weight: 500;
    left: 0;
    margin: 0;
    max-width: 16rem;
    padding: .3rem 1rem;
    position: absolute;
    top: 1.3rem;
    z-index: 33;

    &.light-gray {
      background-color: $color-grau-2;
    }

    &.dark-gray {
      background-color: $color-schwarz;
    }

    &.very-light-gray {
      background-color: $color-grau-1;
    }

    &.hempel-blue {
      background-color: $color-hempel-blau;
    }

    &.white {
      background-color: $color-weiss;
    }

    &.fg-grey-2 {
      color: $color-grau-2;
    }

    &.fg-white{
      color: $color-weiss;
    }

    &.fg-black{
      color: $color-schwarz;
    }
  }

  &__overlay {
    background-color: $transparent-blue;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: $transition-fading;
    width: 100%;
    z-index: 22;
  }
}


// set heights for focuspoint
.image-teaser__link--full {
  .focuspoint {
    height: 16.625rem;
    margin-bottom: $grid-gutter-width;
  }
}


.image-teaser__link--fullhalf {
  .focuspoint {
    height: 20.625rem;
    margin-bottom: $grid-gutter-width;
  }
}

.image-teaser__link--half {
  .focuspoint {
    height: calc(10.3125rem - 15px);
    margin-bottom: $grid-gutter-width;
  }
}

@include media-breakpoint-down(md) {
  .image-teaser {
    .focuspoint {
      height: 16.625rem;
    }
  }
}
