.page-transition {
  background-color: $white;
  color: $gray-100;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  //transition: $page-trans; -page speedup #100135
  vertical-align: middle;
  width: 100vw;
  z-index: 1050;

  &--showContent {
    width: 0;

    .loader {
      opacity: 0;
      transition: opacity .2s linear;
    }
  }

  &--hideContent {
    left: 0;
    right: auto;
    width: 100vw;

    .loader {
      transition: opacity .2s linear .6s;
    }
  }

  .loader {
    display: inline-block;
    height: 1em;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
