@media print {
  section {
    opacity: 1;
  }

  .keyvisual,
  .main-footer {
    display: none;
  }

  a {
    text-decoration: none;
  }
}
