.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background: $overlay-background;
  z-index: 9999;

  .spinner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $spinner-width;
    height: $spinner-height;
  }
}
