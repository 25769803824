// background for selectbox and inputs
.custom-select {
  background: inherit;
  background-color: $color-grau-2;
}

// .input-group {
//   &:not(&--button) {
//     &:after {
//       border-bottom: 0;
//       border-left: .4em solid transparent;
//       border-right: .4em solid transparent;
//       border-top: .5em solid;
//       content: '';
//       display: inline-block;
//       height: 0;
//       position: absolute;
//       right: 1rem;
//       top: 50%;
//       transform: translateY(-50%);
//       width: 0;
//       z-index: 155;
//     }
//   }
// }

.selectize-control.single {
  &.custom-selectList {
    width: 100%;

    .selectize-input {
      &.full {
        background-color: $color-grau-2;
        color: $color-schwarz;
       }

      &::after {
        border-color: $color-schwarz transparent;
      }
    }


    .selectize-dropdown {
      border: 1px solid #d0d0d0;
      box-shadow: 0 1px 3px rgba(0,0,0,.1);
    }
  }
}
