.sticky-nav {
  @include media-breakpoint-down(xs) {
    background-color: theme-color('secondary');
    padding: 0;
  }
  color: $white;
  padding-bottom: $grid-gutter-width;
  transition: transform .2s cubic-bezier(.41, .53, .6, .39);
  z-index: 888;

  .nav {
    @include media-breakpoint-down(xs) {
      display: none;
    }

    background-color: theme-color('secondary');
    box-shadow: 0 0 1px rgba(57, 70, 78, 0.9), 0 20px 55px -8px rgba(57, 70, 78, 0.71);
    // justify-content: space-between;
    padding: .5rem 0;
  }

  .nav-link {
    color: $white;

    &:hover {
      opacity: .75;
    }
  }

  &__mobile {
    @include media-breakpoint-down(xs) {
      display: block;
    }

    box-shadow: 0 0 1px rgba(57, 70, 78, 0.9), 0 20px 55px -8px rgba(57, 70, 78, 0.71);

    display: none;

    .mobile-nav__item {
      cursor: pointer;
    }

    .mobile-nav__item[aria-expanded='false'] {
      .fas {
        &::before {
          content: '\f067';
        }
      }
    }

    .mobile-nav__item[aria-expanded='true'] {
      .fas {
        &::before {
          content: '\f068';
        }
      }
    }

    .mobile-nav__icon {
      transform: translateY(-50%) scale(1);
    }

    .sticky-list {
      @extend .list-unstyled;
      margin-bottom: 0;
      padding-bottom: .5rem;

      .nav-item {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(247, 247, 247, .23);
        }
      }
    }
  }
}

// if header is hidden
._header-hidden {
  .sticky-nav {
    &.is_stuck {
      transform: translateY(-123px);
    }
  }
}
