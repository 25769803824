.calculator {
  background-color: theme-color('primary');
  color: $white;

  &__title {
    color: $white;
    margin-bottom: 10px;
  }

  &__header {
    padding: 1rem;
  }

  img {
    @extend .img-fluid;
    width: 100%;
  }

  &__btn {
    padding-bottom: 2rem;
    padding-top: 2rem;
    text-align: center;

    .btn-primary {
      background-color: $color-hempel-blau-80;
      border-color: $color-hempel-blau-80;
    }
  }
}
