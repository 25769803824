// This stylesheet generated by Transfonter (https://transfonter.org) on April 6, 2018 12:34 PM

@font-face {
  font-family: 'Noto Sans Cond';
  font-style: normal;
  font-weight: 700;
  src: url('#{$icon-font-path}NotoSans-CondensedBold.eot');
  src: url('#{$icon-font-path}NotoSans-CondensedBold.eot?#iefix') format('embedded-opentype'),
  url('#{$icon-font-path}NotoSans-CondensedBold.woff2') format('woff2'),
  url('#{$icon-font-path}NotoSans-CondensedBold.woff') format('woff'),
  url('#{$icon-font-path}NotoSans-CondensedBold.ttf') format('truetype'),
  url('#{$icon-font-path}NotoSans-CondensedBold.svg#NotoSans-CondensedBold') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Cond';
  font-style: normal;
  font-weight: 400;
  src: url('#{$icon-font-path}NotoSans-CondensedMedium.eot');
  src: url('#{$icon-font-path}NotoSans-CondensedMedium.eot?#iefix') format('embedded-opentype'),
  url('#{$icon-font-path}NotoSans-CondensedMedium.woff2') format('woff2'),
  url('#{$icon-font-path}NotoSans-CondensedMedium.woff') format('woff'),
  url('#{$icon-font-path}NotoSans-CondensedMedium.ttf') format('truetype'),
  url('#{$icon-font-path}NotoSans-CondensedMedium.svg#NotoSans-CondensedMedium') format('svg');
  font-display: swap;
}


@font-face {
  font-family: 'Noto Sans Cond';
  font-style: normal;
  font-weight: 500;
  src: url('#{$icon-font-path}NotoSans-CondensedSemiBold.eot');
  src: url('#{$icon-font-path}NotoSans-CondensedSemiBold.eot?#iefix') format('embedded-opentype'),
  url('#{$icon-font-path}NotoSans-CondensedSemiBold.woff2') format('woff2'),
  url('#{$icon-font-path}NotoSans-CondensedSemiBold.woff') format('woff'),
  url('#{$icon-font-path}NotoSans-CondensedSemiBold.ttf') format('truetype'),
  url('#{$icon-font-path}NotoSans-CondensedSemiBold.svg#NotoSans-CondensedSemiBold') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans SemCond';
  font-style: normal;
  font-weight: 700;
  src: url('#{$icon-font-path}NotoSans-SemiCondensedBold.eot');
  src: url('#{$icon-font-path}NotoSans-SemiCondensedBold.eot?#iefix') format('embedded-opentype'),
  url('#{$icon-font-path}NotoSans-SemiCondensedBold.woff2') format('woff2'),
  url('#{$icon-font-path}NotoSans-SemiCondensedBold.woff') format('woff'),
  url('#{$icon-font-path}NotoSans-SemiCondensedBold.ttf') format('truetype'),
  url('#{$icon-font-path}NotoSans-SemiCondensedBold.svg#NotoSans-SemiCondensedBold') format('svg');
  font-display: swap;
}
