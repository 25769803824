.main-header {
  @include media-breakpoint-down(md) {
    padding: 1rem 0;
  }
  background-color: $white;
  box-shadow: none;
  display: block;
  position: relative;
  top: 0;
  transition: box-shadow .2s cubic-bezier(.41, .53, .6, .39), transform .2s cubic-bezier(.41, .53, .6, .39);
  width: 100%;
  z-index: 1030;

  > .container {
    position: relative;
  }

  &__nav {
    @include media-breakpoint-down(md) {
      display: none;
    }
    margin: 1.2rem 0;
    position: relative;
    width: 100%;

    .nav {
      align-items: center;
      justify-content: flex-end;
    }

    .nav-item {
      &:last-child {
        .nav-link { margin-right: 0; }
      }
    }

    .nav-link {
      @include font-meta-nativation;
      margin-right: 2rem;
      padding: 0;
      transition: $transition-base;

      &:hover {
        color: $font-meta-navigation-important-color;
      }
    }

    // Download link style
    .nav-link--important {
      color: $font-meta-navigation-important-color;
      position: relative;
    }

    .nav-link__download {
      left: -1.6rem;
      position: absolute;
      top: -.1rem;
    }

    // Search link styles
    .nav-link--search {
      border: 2px solid $font-meta-nativation-color;
      border-radius: 100%;
      height: 30px;
      position: relative;
      text-align: center;
      width: 30px;
      z-index: 66;

      &.active {
        border: 0;
        height: auto;
        width: auto;

        .fas {
          font-size: 1.7rem;
          height: auto;
          padding: 0;
          width: auto;

          &::before {
            content: "\f057";
          }
        }
      }

      &:hover {
        border-color: $font-meta-navigation-important-color;
      }

      .fas {
        display: block;
        font-size: 15px;
        height: 100%;
        padding-top: 5.5px;
        width: 100%;
      }

      + .form-search {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition-fading;
        width: 48.3%;
        z-index: 65;

        &.active {
          opacity: 1;
          pointer-events: auto;
        }

        .form-control {
          background-color: $color-grau-2;
          border: 0;
          width: calc(100% - 38px);
        }

        .form-row {
          position: relative;
        }
      }
    }
  }

  .nav-link--submit {
    font-size: 1.7rem;
    height: 100%;
    padding: .15rem 0;
    position: absolute;
    right: 48px;
    top: 0;
  }

  .main-header__nav--meta {
    margin-bottom: 0;
    margin-top: 0;

    .nav-item {
      height: 3.3rem;
      position: relative;
    }

    .nav-link {
      @include font-main-navigation;
      border-bottom: 9px solid transparent;
      padding: 0;
      padding-bottom: .7rem;

      // &:after {
      //   background-color: theme-color('primary');
      //   bottom: 0;
      //   content: '';
      //   height: 9px;
      //   left: calc(50% - 1rem);
      //   position: absolute;
      //   transform: translateX(-50%);
      //   transition: width .2s ease-in-out;
      //   width: 0;
      // }

      &:hover,
      &.active-hover {
        @include font-main-navigation-hover;
        border-color: theme-color('primary');
      }
    }
  }

  &__logo {
    @include media-breakpoint-down(md) {
      left: 0;
      position: relative;
      top: 0;
      display: inline-block;

      img {
        width: 10.625rem;
      }
    }
    left: .9375rem;
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--sticky {
    box-shadow: 0 0 17px 3px rgba(0, 0, 0, .12);
    left: 0;
    position: fixed;
    top: 0;
    z-index: 1030;
  }

  &--hidden {
    transform: translateY(-100%);
  }
}


.hamburger-icon {
  @include media-breakpoint-down(md) {
    display: block;
  }

  display: none;
  position: absolute;
  right: 0;
  top: 0;
}
