// .flyout {
//   position: static !important;
//   .flyout-menu {
//     position: absolute;
//     background: $flyout-background;
//     left: 0;
//     width: 100%;
//     opacity: 0;
//     visibility: hidden;
//     transition: visibility $animation-base, opacity $animation-base;
//     z-index: 1;
//   }
// }
//
// .flyout:hover {
//   .flyout-menu {
//     visibility: visible;
//     opacity: 1;
//   }
// }
//



//Custom mega flyout
.nav-item {
  // pointer-events: none;
  &:hover {
    .sub-nav {
      // display: block;
      transition: transform .3s ease-in, opacity .3s ease-in, visibility .3s ease-in;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
      z-index: 10;
    }
  }
}

.sub-nav {
  background: $white;
  left: -100%;
  opacity: 0;
  padding: 0;
  position: absolute;
  // display: none;
  top: 100%;
  transform: translateY(+3%);
  transition: transform .3s ease-in .5s, opacity .3s ease-in .5s, visibility .3s ease-in .5s;
  visibility: hidden;
  z-index: -1;


  .mega-flyout {
    border-top: 2px solid theme-color('primary');

    &__container {
      @include media-breakpoint-down(lg) {
        min-width: 200px;
      }
      min-width: 250px;
      position: relative;
      width: 100%;
    }

    &__heading {
      background: theme-color('primary');
      color: $white;
      padding: $grid-gutter-width;
    }

    &__title {
      color: $white;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    &__text {
      margin-bottom: 0;
    }

    &__list {
      @extend .list-unstyled;
      background-color: $color-grau-1;
    }

    &__links {
      display: block;
      padding: 10px 0;
      position: relative;
      &:after {
        border-bottom: .4rem solid transparent;
        border-left: .4rem solid theme-color('primary');
        border-top: .4rem solid transparent;
        content: '';
        opacity: 0;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity .2s ease-in-out;
      }

      &--has-children {
        &:after {
          opacity: 1;
        }
      }
    }

    &__item {
      padding-left: $grid-gutter-width;
      transition: background .2s ease-in-out;

      &:hover {
        background-color: $white;

        .mega-flyout__links {
          color: theme-color('primary');
        }

        .mega-flyout__children {
          left: 100%;
          opacity: 1;
          visibility: visible;
          transition: left .3s ease-in, opacity .3s ease-in .175s;

          &__links {
            display: block;
            margin-bottom: .6rem;
            transition: color .2s ease-in-out;

            &:hover {
              color: theme-color('primary');
            }
          }
        }
      }
    }
  }


  &.sub-nav--teaser {
    left: auto;
    right: 0;


    .mega-flyout__container--teaser {
      background-color: $color-grau-1;
      @include make-container;
      width: 900px;
    }

    .mega-flyout__wrapper {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: center;
      // padding: $grid-gutter-width;
      // width: 100%;
      @include make-row;
      padding-top: 15px;
    }

    .mega-flyout__teaser {
      @include make-col-ready;
      @include make-col(3);
      margin: 0 15px 15px;
      max-width: calc(25% - 30px);
      padding-left: 0;
      padding-right: 0;
      position: relative;

      img {
        @extend .img-fluid;
        width: 100%;
      }

      &:hover {
        .mega-flyout__teaser__text {
          background-color: theme-color('primary');
        }
      }
    }

    .mega-flyout__teaser__text {
      background-color: $color-schwarz;
      bottom: 0;
      color: $white;
      left: 0;
      margin-bottom: 0;
      padding: .5rem;
      position: absolute;
      text-align: center;
      transition: background .2s ease-in-out;
      width: 100%;
    }
  }
}

.mega-flyout__children {
  @include media-breakpoint-down(lg) {
    // min-height: calc(100% + 2px);
  }
  background: $white;
  border-top: 2px solid theme-color('primary');
  height: calc(100% + 2px);
  left: -100%;
  min-width: 100%;
  opacity: 0;
  padding: $grid-gutter-width;
  position: absolute;
  top: -2px;
  transition: left .3s ease-in, opacity .2s ease-in;
  visibility: hidden;
  width: auto;
  z-index: -2;
}

.mega-flyout__menu-wrapper {
  display: flex;
  flex-direction: row;
}

.mega-flyout__menu {
  @extend .list-unstyled;
  min-width: 175px;
  width: auto;
}
