.main-footer {
  @include media-breakpoint-down(xs) {
    padding: 1rem;
  }
  @extend .padding-top-bottom;

  &__title {
    color: $white;
    margin-bottom: .75rem;

    a {
      color: $white;
    }
  }

  &__nav {
    ul {
      @extend .list-unstyled;
      a {
        color: $white;
        transition: color .15s ease-in-out;

        &:hover {
          color: $color-hempel-blau-60;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .social-icons {
      position: relative;
      &__link {
        margin-right: $half-gutter-width;

        &--text {
          @include media-breakpoint-down(xs) {
            justify-content: flex-start;
            margin-top: 1rem;
            width: 100%;
          }
          align-items: center;
          display: inline-flex;
          justify-content: center;
          margin: 0;
          position: relative;
          z-index: 3;
          span { transition: all .15s ease-in-out; }

          &.active {
            pointer-events: none;
            position: relative;
            z-index: 34;

            #Combined-Shape {
              @include media-breakpoint-down(xs) {
                fill: $white;
              }
              // fill: $color-schwarz;
            }

            span {
              @include media-breakpoint-down(xs) {
                opacity: 1;
              }
              // opacity: 0;
            }
          }

          &:hover {
            span {color: $color-hempel-blau-60};
          }

          span {
            color: $white;
            padding-left: 8px;
          }

          &.newsletter {
            @include media-breakpoint-down(xs) {
              display: none;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .form-inline label {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}


.form-wrapper {
  color: $white;
  a {
    margin: 0.2rem;
    color: #83b4f8;
  }
}

.form-newsletter {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  display: none;
  left: 0;
  opacity: 0;
  position: relative;
  transition: all .15s ease-in-out;

  .form-control {
    padding-right: 35px;
    width: 100%;
  }

  &.active {
    height: auto;
    opacity: 1;
    display: block;
  }

  .form-wrapper {
    color: $white;

    .form-check {
      margin-bottom: 0.5rem;
    }
  }

  .form-group {
    position: relative;

    .fas {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: 20px;
      height: 79%;
      opacity: .5;
      padding-left: 10px;
      padding-right: 10px;
      position: absolute;
      right: 0;
      top: 4px;
      transition: opacity .2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.newsletter-mobile {
  display: none;

  .form-mobile {
    @include media-breakpoint-down(xs) {
      display: none;
    }
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    opacity: 0;
    position: relative;
    transition: all .15s ease-in-out;

    &.active {
      opacity: 1;
      display: block;

      .btn-primary {
        width: 100%;
      }
    }
  }

  .form-group {
    position: relative;

    .fas {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: 20px;
      height: 79%;
      opacity: .5;
      padding-left: 10px;
      padding-right: 10px;
      position: absolute;
      right: 0;
      top: 4px;
      transition: opacity .2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .form-control {
    padding-left: 10px;
    padding-right: 35px;
  }
}


@include media-breakpoint-down(xs) {
  .form-inline {
    &.form-newsletter {
      display: none;
    }
  }

  .newsletter-mobile {
    display: block;
  }
}






// footer mobile menu
.main-footer__list {
  @include media-breakpoint-down(xs) {
    display: none;
  }
  > .row > .col {
    @media (max-width: 768px) {
      flex: 1 1 14em;
    }
  }
}

.mobile-nav__container--footer {
  @include media-breakpoint-down(xs) {
    color: $white;
    display: block;
    height: auto;
    margin-bottom: 1rem;
    overflow-y: inherit;

    .mobile-nav__list {
      opacity: 1;
      transform: none;
      transition: unset;
    }

    .mobile-nav__link {
      padding-left: 0;
    }

    .mobile-nav__icon {
      right: 6px;
    }

    [data-parent="#footer-accordion"] {
      padding-left: 1rem;
    }
  }
  display: none;
}
