.keyvisual {
  &--video {
    @include media-breakpoint-down(xs) {
      height: auto;
    }
    //height: 50vh;
    overflow: hidden;

    &__video {
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;

      iframe {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }
  }

  &--image {
    height: 15rem;
    overflow: hidden;
    position: relative;

    .keyvisual__overlay  {
      bottom: 15%;
      position: absolute;
    }
  }
}
