.media {
  &--details {
    margin-bottom: $grid-gutter-width;

    }
    .media__title {
      @extend .image-teaser__title;
    }

    &--full {
    @include media-breakpoint-down(md) {
      flex-direction: row;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .focuspoint {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      width: 33%;
      }
    }
  }


  .media__title {
    @extend .image-teaser__title;
  }

.media-body {
  &--details {
    padding: 25px;

    .media__links {
      display: flex;
      justify-content: space-between;

      ul, ol {
        width: (100%/3);
      }

      ul li a {
        color: theme-color('primary');
        text-decoration: underline;
        transition: color .2s ease-in-out;

        &:hover {
          color: darken(theme-color('primary'), 50%);
        }
      }
    }

  }
}
