.detailsCaseStudy {
  &__wrapper {
    border: 2px solid #ccc;
    padding: 1rem;
  }

  &__title {
    margin-bottom: 12px;
    margin-top: $grid-gutter-width;
  }

}
