.feedback {
  &__wrapper {
    border: 2px solid #ccc;
    padding: 1rem;
    position: relative;
  }

  &__icons {
    margin: calc(#{$grid-gutter-width} / 2) 0;
    .feedback__links {
      background: #969899;
      border-radius: 100%;
      display: inline-block;
      height: 50px;
      margin-right: 1.25rem;
      position: relative;
      width: 50px;

      i {
        color: $white;
        display: inline-block;
        font-size: 2rem;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: $transition-fading;
      }

      &:hover,
      &.active {
        i {
          color: theme-color('primary');
        }
      }
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: calc(#{$grid-gutter-width} / 2);

    .btn {
      line-height: 1rem;
      &:first-child {
        margin-bottom: calc(#{$grid-gutter-width} / 2);
      }
    }
  }

  &__comment {
    display: none;
  }

  &__sent {
    align-items: center;
    background: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all .45s cubic-bezier(0.4, 0, 1, 1);
    visibility: hidden;
    width: 100%;

    &.active {
      opacity: 1;
      visibility: visible;

      .far {
        transform: translateY(0);
      }

      .feedback__sent__text {
        transform: translateY(0);
      }
    }

    .far {
      color: theme-color('primary');
      font-size: 2.5rem;
      transform: translateY(20px);
      transition: all .3s cubic-bezier(0.4, 0, 1, 1);
    }

    &__text {
      margin: .625 0;
      transform: translateY(20px);
      transition: all .3s cubic-bezier(0.4, 0, 1, 1);

      p {
        margin-top: 1rem;
      }
    }
  }
}
