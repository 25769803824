.certificate {
  @include media-breakpoint-down(md) {
    margin: $grid-gutter-width 0;
  }
  &__images {
    align-items: center;
    display: flex;
    justify-content: space-between;

    img {
      height: 100px;
      width: 100px;
    }
  }
}
