.case-study {
  @include make-row;
  @include media-breakpoint-up(lg) {
    margin-left: 0;
    margin-right: 0;
  }
  border: 2px solid $color-grau-2;

  &__image {
    @include make-col-ready;

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(6);
      padding-left: 0;
      padding-right: 0;
    }

  }


  &__data {
    @include make-col-ready;

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(6);
      padding: 2rem 1.875rem;

      .case-study__link {
        color: $color-hempel-blau;
        transition: $transition-color;

        &:hover {
          color: $color-hempel-blau-80;
        }
      }
    }

  }



  .focuspoint {
    height: 16rem;
  }
}


.media {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    margin-bottom: $grid-gutter-width;
  }
  background-color: $gray-200;
  border: 2px solid $color-grau-2;
  position: relative;

  .media-body {
    @include media-breakpoint-down(md) {
      padding: 1.6rem;
    }
    flex-basis: 50%;
    height: 100%;
    padding: $grid-gutter-width;
    width: 100%;

    .case-study__link {
      color: $color-hempel-blau;
      display: block;
      margin-bottom: 1rem;

      &:hover {
        color: $color-hempel-blau-80;
      }
    }

    .btn-primary {
      @include media-breakpoint-down(xs) {
        bottom: inherit;
        position: relative;
        right: inherit;
      }
      bottom: 15px;
      position: absolute;
      right: 15px;
    }
  }

  &__image {
    flex-basis: 50%;
    flex-grow: 1;

    .focuspoint {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      align-self: stretch;
      height: 300px;
      width: 100%;
    }
  }

  &__title {
    a {
      color: $white;
    }
  }


  // a {
  //   display: block;
  //   flex-basis: 50%;
  //   flex-grow: 1;
  //   height: 100%;
  //   width: 100%;
  // }
}
