.nav-item {
  &.dropdown {
    .dropdown-item {
      &:hover {
        background-color: theme-color('primary');
        color: $white;
      }
    }
  }
}

.dropdown__lang,
.dropdown__country {
  @extend .list-unstyled;
}

.dropdown__lang {
  min-width: 3rem;

  .dropdown-item {
    padding: .25rem .7rem;
  }
}
