.mobile-nav {
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
  background-color: theme-color('primary');
  color: $white;
  height: 100%;
  min-width: 19rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform .5s ease-in-out;
  z-index: 1080;

  &.active {
    transform: translateX(0);

    .mobile-nav__list {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      @include delaytranstion;
    }
  }

  &__container {
    height: 70vh;
    overflow-y: auto;
    // overflow: scroll;
  }

  &__sub {
    margin-bottom: 1rem;
    position: relative;
  }

  &__sub__link {
    color: #fff;
  }

  &__list {
    @extend .list-unstyled;
    border-top: 1px solid rgba(255, 255, 255, .3);
    margin: 0;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: all 450ms cubic-bezier(.455, .03, .515, .955);

    &:first-child {
      border-top: 0;
    }

    li > ul {
      list-style: none;
    }
  }

  &__item {
    position: relative;
  }

  &__icon {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%) scale(1.5);

    .fas {
      // color: theme-color('primary');
      // font-family: 'fontawesome';
      // font-weight: normal;
    }
  }

  &__link {
    color: #fff;
    display: inline-block;
    padding: .8rem 1rem;
    position: relative;
    transition: color .2s ease-in-out;
  }
}

.mobile-nav__icon[aria-expanded='false'] {
  .fas {
    &::before {
      content: '\f107';
    }
  }
}

.mobile-nav__icon[aria-expanded='true'] {
  .fas {
    &::before {
      content: '\f106';
    }
  }
}




// Sub nav styles
.sub-accordion {
  @extend .list-unstyled;

  > .mobile-nav__sub {
    > a {
      // color: theme-color('primary');
    }
  }
}





// mobile Header
.mobile-nav__header {
  background-color: $color-grau-2;
  border-top: 3px solid theme-color('primary');

  .mobile-nav__search {
    align-items: center;
    display: flex;
  }

  .mobile-nav__top {
    border-bottom: 1px solid theme-color('primary');
    display: flex;
    justify-content: space-between;
  }

  .mobile-nav__bottom {
    display: flex;

    .mobile-nav__link {
      border-right: 1px solid theme-color('primary');
      flex-basis: 50%;
      width: 50%;

      &:last-child {
        border: 0;
      }
    }
  }

  .mobile-nav__link {
    color: theme-color('primary');
  }

  .form-select {
    background-color: $white;
    display: none;

    .selectize-control {
      margin: 0;
      width: auto;

      &.country-select {
        border-bottom: 1px solid $color-grau-1;
      }
    }

    .selectize-dropdown {
      border: 1px solid #d0d0d0;
      box-shadow: 0 1px 3px rgba(0,0,0,.1);
    }
  }
}
