.werkstoff-outlet {

  &__img {
    position: relative;
    overflow: hidden;

    .header-img {
      height: 100%;
      max-width: inherit;
      width: auto;
    }
  }

  &__logo {
    @extend .econoxx-teaser-small__logo;
  }

  &__text {
    margin: 15px;

    &-heading {
      color: $white;
      margin: 0;
    }

    &-small {
      margin: 0;
    }
  }

  &__title {
    background-color: $color-econoxx-grun;
    color: $white;
    margin: 0;
    padding: .2rem;
    text-align: center;
  }


  &__item {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: .5rem 1.85rem;

    &:nth-child(odd) {
      background-color: $color-grau-1;
    }

    @include media-breakpoint-up(sm) {
      > div {
        width: 34%;
      }
    }


    &-title {
      @include media-breakpoint-down(xs) {
        min-width: inherit;
        width: 90%;
      }
      align-items: center;
      display: flex;
      min-width: 10.5rem;

      img {
        height: 3rem;
        margin-right: .5rem;
      }
    }

    &-number {
      @include media-breakpoint-down(xs) {
        width: 30%;
      }
      text-align: center;
    }
  }

  &__button {
    text-align: right;
  }

  &__bottom {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
    background-color: $color-econoxx-grun;
    display: flex;
    justify-content: space-between;
    padding: .5rem 1.85rem;

    .btn-green {
      @include media-breakpoint-down(xs) {
        margin-top: .7rem;
      }
      background-color: #72910A;
      border-color: #72910A;
      min-width: 10.3125rem;
    }
  }
}
