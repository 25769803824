.search-result {
  padding: 3rem 0;

  .form-search {
    position: relative;

    .search-icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);

      .fas {
        font-size: 1.75rem;
      }
    }

    .form-control {
      font-size: 1.75rem;
      height: 4rem;
    }
  }
}


.search-result-data {
  &__top {
    background-color: $color-grau-1;
    position: relative;

    .nav-tabs {
      border: 0;

      .nav-link {
        border: 0;
        border-radius: 0;
        padding: 1rem 2rem;
        text-transform: uppercase;
        transition: background .2s linear;

        &:first-child {
          margin-right: .7rem;
        }

        &.active {
          border-color: transparent;
          color: theme-color('primary');
        }

        &:hover {
          background-color: $white;
        }
      }
    }
  }

  &__title {
    font-size: 2rem;
    padding: 2rem 0;

    strong {
      color: theme-color('primary');
    }
  }


  &__item {
    padding: 2.5rem 0;

    &:not(:first-child) {
      border-top: 1px solid $color-grau-2;
    }

    strong {
      font-weight: 700;
    }

    a {
      transition: color .2s ease-in-out;

      &:hover {
        color: theme-color('primary');
      }
    }

    .marked {
      background: $color-hempel-blau;
      color: white;
      padding: 0 .4rem;
    }
  }


  &__result {
    .pagination {
      margin-bottom: 3rem;
      .page-item.disabled .page-link {
        background-color: theme-color('primary');
        color: $white;
      }
    }
  }
}
