.econoxx {
  .focuspoint {
    height: 14.375rem;
  }

  &__body {
    position: relative;
  }

  &__logo {
    left: $grid-gutter-width;
    position: absolute;
    top: $grid-gutter-width;

    img {
      height: 4rem;
      width: 12.5rem;
    }

    p {
      padding-left: 4.5rem;
    }
  }

  &__text {
    @include media-breakpoint-down(sm) {
      display: none;
    }
    bottom: 1.5rem;
    left: calc(#{$grid-gutter-width} + 4.5rem);
    position: absolute;
  }

  &__title {
    color: $white;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.25rem;
    margin: 0;
  }

  &__footer {
    @include media-breakpoint-down(xs) {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-right: 0;
    }
    background-color: $color-econoxx-grun;
    padding: 1.25rem 0;
    text-align: center;

    .btn {
      @include media-breakpoint-down(xs) {
        margin-right: 0;
      }
      @include media-breakpoint-down(md) {
        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      margin-right: 1.2rem;
      @extend .btn-gray;
    }
  }
}





// small Teaser
.econoxx-teaser-small {
  margin-top: 30px;

  &__body {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 4rem;
    }
  }

  &__footer {
    align-items: center;
    background-color: $color-econoxx-grun;
    display: flex;
    flex-direction: column;
    padding: 2rem 0;

    .btn {
      // @extend .btn-gray;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    margin: 15px;

    &-heading {
      color: $white;
      margin: 0;
    }

    &-small {
      margin: 0;
    }

  }


}
